// ../shared/dist/index.mjs
import { z } from "zod";
import { z as z2 } from "zod";
function objectKeysToZodEnum(object, params) {
  const keys = Object.keys(object).map(
    (key) => typeof key === "number" ? `${key}` : key
  );
  const firstKey = keys[0];
  if (firstKey === void 0) {
    throw new Error("Expecting at least one key");
  }
  const values = [firstKey, ...keys.slice(1)];
  return z.enum(values, params);
}
var readingTypes = {
  CONTROL: "CONTROL",
  EXTRA_INVOICE: "EXTRA_INVOICE"
};
var readingTypeSchema = objectKeysToZodEnum(readingTypes);
var invoiceSpotDataSchema = z2.object({
  dateTime: z2.date(),
  date: z2.date().nullish().describe("DEPRECATED - for backwards compatibility with cached client only"),
  hour: z2.number().nullish().describe("DEPRECATED - for backwards compatibility with cached client only"),
  unitPriceKWh: z2.number(),
  ratio: z2.number().optional(),
  constant: z2.number().optional(),
  quantityKWh: z2.number(),
  totalPrice: z2.number(),
  vatMultiplier: z2.number()
});
var spotInvoiceDetailSchema = z2.object({
  contractId: z2.number().int(),
  ean: z2.string(),
  billedSince: z2.date(),
  billedUntil: z2.date(),
  deliveryPointType: z2.enum(["consumption", "production"]),
  deliveryPointId: z2.number().int(),
  spotData: z2.array(invoiceSpotDataSchema)
});
var TypeFilter = /* @__PURE__ */ ((TypeFilter2) => {
  TypeFilter2["Advances"] = "advances";
  TypeFilter2["Invoices"] = "invoices";
  TypeFilter2["Billing"] = "billing";
  TypeFilter2["CreditNote"] = "creditNote";
  TypeFilter2["DebitNote"] = "debitNote";
  TypeFilter2["Fine"] = "fine";
  TypeFilter2["Inclusion"] = "inclusion";
  TypeFilter2["Claim"] = "claim";
  TypeFilter2["SelfBillingInvoice"] = "selfBillingInvoice";
  TypeFilter2["ProductionBilling"] = "productionBilling";
  TypeFilter2["ProductionInvoiceBasis"] = "productionInvoiceBasis";
  return TypeFilter2;
})(TypeFilter || {});
var StateFilter = /* @__PURE__ */ ((StateFilter2) => {
  StateFilter2["Paid"] = "paid";
  StateFilter2["Unpaid"] = "unpaid";
  StateFilter2["Cancelled"] = "cancelled";
  StateFilter2["Included"] = "included";
  StateFilter2["Invoiced"] = "invoiced";
  StateFilter2["Uninvoiced"] = "uninvoiced";
  StateFilter2["Unknown"] = "unknownState";
  return StateFilter2;
})(StateFilter || {});
var DueDateFilter = /* @__PURE__ */ ((DueDateFilter2) => {
  DueDateFilter2["ApproachingDueDate"] = "approachingDueDate";
  DueDateFilter2["AfterDueDate"] = "afterDueDate";
  DueDateFilter2["FarFromDueDate"] = "farFromDueDate";
  DueDateFilter2["Unknown"] = "unknownDueDate";
  return DueDateFilter2;
})(DueDateFilter || {});
var getAllEnumValues = (enm) => {
  const enumValues = [];
  for (const key in enm) {
    const value = enm[key];
    if (!value) {
      throw new Error(`Error while parsing enum - ${key} not found`);
    }
    enumValues.push(value);
  }
  return enumValues;
};
var defaultPaymentTypeFilter = getAllEnumValues(TypeFilter);
var defaultPaymentStateFilter = getAllEnumValues(StateFilter);
var defaultPaymentDueDateFilter = getAllEnumValues(DueDateFilter);
function containsOnlyDigits(string) {
  for (const char of string) {
    if (char < "0" || char > "9") {
      return false;
    }
  }
  return string.length > 0;
}
function isPhoneNumber(phone) {
  return /^(\+\d{1,3})?\d{9,12}$/i.test(phone);
}
function isOrientationNumber(string) {
  if (string === void 0) {
    return true;
  }
  if (string.length > 4) {
    return false;
  }
  for (let i = 0; i < string.length; ++i) {
    const char = string[i] ?? "";
    if (i < 3 && (char < "0" || char > "9")) {
      return false;
    }
    if (i === 3 && (char.toLocaleLowerCase() < "a" || char.toLowerCase() > "z")) {
      return false;
    }
    return true;
  }
}
var EAN_LENGTH = 18;
var EAN_PREFIX = "8591824";
function isValidEan(value) {
  if (value.length !== EAN_LENGTH) {
    return false;
  }
  const barcode = value.slice(0, -1);
  const lastIndex = barcode.length - 1;
  let sum = 0;
  for (let i = 0; i <= lastIndex; ++i) {
    const digit = Number.parseInt(barcode[lastIndex - i] ?? "");
    if (Number.isNaN(digit)) {
      return false;
    }
    sum += i % 2 === 0 ? digit * 3 : digit;
  }
  const mod = sum % 10;
  const calculatedChecksum = mod === 0 ? 0 : 10 - mod;
  const givenChecksum = value.slice(-1);
  return calculatedChecksum.toString() === givenChecksum;
}
function isValidBankAccountPrefix(value) {
  if (value === void 0) {
    return true;
  }
  const prefixWeights = [10, 5, 8, 4, 2, 1];
  const string = value.padStart(prefixWeights.length, "0");
  let sum = 0;
  for (const [i, prefixWeight] of prefixWeights.entries()) {
    const digit = Number.parseInt(string[i] ?? "");
    if (Number.isNaN(digit)) {
      return false;
    }
    sum += digit * prefixWeight;
  }
  return sum % 11 === 0;
}
function isValidBankAccountNumber(value) {
  const baseWeights = [6, 3, 7, 9, 10, 5, 8, 4, 2, 1];
  const string = value.padStart(baseWeights.length, "0");
  let sum = 0;
  for (const [i, baseWeight] of baseWeights.entries()) {
    const digit = Number.parseInt(string[i] ?? "");
    if (Number.isNaN(digit)) {
      return false;
    }
    sum += digit * baseWeight;
  }
  return sum % 11 === 0;
}
var POSTAL_CODE_LENGTH = 5;
function isPostalCode(value) {
  if (value.length !== POSTAL_CODE_LENGTH) {
    return false;
  }
  const firstDigit = value[0] ?? "";
  if (firstDigit < "1" || firstDigit > "9") {
    return false;
  }
  return containsOnlyDigits(value);
}

// src/lib/address.ts
import { z as z3 } from "zod";

// src/lib/country.ts
var countryOptions = {
  AF: "Afgh\xE1nist\xE1n",
  AX: "\xC5landy",
  AL: "Alb\xE1nie",
  DZ: "Al\u017E\xEDrsko",
  AS: "Americk\xE1 Samoa",
  VI: "Americk\xE9 Panensk\xE9 ostrovy",
  AD: "Andorra",
  AO: "Angola",
  AI: "Anguilla",
  AQ: "Antarktida",
  AG: "Antigua a Barbuda",
  AR: "Argentina",
  AM: "Arm\xE9nie",
  AW: "Aruba",
  AU: "Austr\xE1lie",
  AZ: "\xC1zerb\xE1jd\u017E\xE1n",
  BS: "Bahamy",
  BH: "Bahrajn",
  BD: "Banglad\xE9\u0161",
  BB: "Barbados",
  BE: "Belgie",
  BZ: "Belize",
  BY: "B\u011Blorusko",
  BJ: "Benin",
  BM: "Bermudy",
  BT: "Bh\xFAt\xE1n",
  BO: "Bol\xEDvie",
  BQ: "Bonaire, Svat\xFD Eustach a Saba",
  BA: "Bosna a Hercegovina",
  BW: "Botswana",
  BV: "Bouvet\u016Fv ostrov",
  BR: "Braz\xEDlie",
  IO: "Britsk\xE9 indickooce\xE1nsk\xE9 \xFAzem\xED",
  VG: "Britsk\xE9 Panensk\xE9 ostrovy",
  BN: "Brunej",
  BG: "Bulharsko",
  BF: "Burkina Faso",
  BI: "Burundi",
  CK: "Cookovy ostrovy",
  CW: "Cura\xE7ao",
  TD: "\u010Cad",
  ME: "\u010Cern\xE1 Hora",
  CZ: "\u010Cesko",
  CN: "\u010C\xEDna",
  DK: "D\xE1nsko",
  CD: "Demokratick\xE1 republika Kongo",
  DM: "Dominika",
  DO: "Dominik\xE1nsk\xE1 republika",
  DJ: "D\u017Eibutsko",
  EG: "Egypt",
  EC: "Ekv\xE1dor",
  ER: "Eritrea",
  EE: "Estonsko",
  ET: "Etiopie",
  FO: "Faersk\xE9 ostrovy",
  FK: "Falklandy (Malv\xEDny)",
  FJ: "Fid\u017Ei",
  PH: "Filip\xEDny",
  FI: "Finsko",
  FR: "Francie",
  GF: "Francouzsk\xE1 Guyana",
  TF: "Francouzsk\xE1 ji\u017En\xED a antarktick\xE1 \xFAzem\xED",
  PF: "Francouzsk\xE1 Polyn\xE9sie",
  GA: "Gabon",
  GM: "Gambie",
  GH: "Ghana",
  GI: "Gibraltar",
  GD: "Grenada",
  GL: "Gr\xF3nsko",
  GE: "Gruzie",
  GP: "Guadeloupe",
  GU: "Guam",
  GT: "Guatemala",
  GN: "Guinea",
  GW: "Guinea-Bissau",
  GG: "Guernsey",
  GY: "Guyana",
  HT: "Haiti",
  HM: "Heard\u016Fv ostrov a McDonaldovy ostrovy",
  HN: "Honduras",
  HK: "Hongkong",
  CL: "Chile",
  HR: "Chorvatsko",
  IN: "Indie",
  ID: "Indon\xE9sie",
  IQ: "Ir\xE1k",
  IR: "\xCDr\xE1n",
  IE: "Irsko",
  IS: "Island",
  IT: "It\xE1lie",
  IL: "Izrael",
  JM: "Jamajka",
  JP: "Japonsko",
  YE: "Jemen",
  JE: "Jersey",
  ZA: "Jihoafrick\xE1 republika",
  GS: "Ji\u017En\xED Georgie a Ji\u017En\xED Sandwichovy ostrovy",
  KR: "Ji\u017En\xED Korea",
  SS: "Ji\u017En\xED S\xFAd\xE1n",
  JO: "Jord\xE1nsko",
  KY: "Kajmansk\xE9 ostrovy",
  KH: "Kambod\u017Ea",
  CM: "Kamerun",
  CA: "Kanada",
  CV: "Kapverdy",
  QA: "Katar",
  KZ: "Kazachst\xE1n",
  KE: "Ke\u0148a",
  KI: "Kiribati",
  CC: "Kokosov\xE9 ostrovy",
  CO: "Kolumbie",
  KM: "Komory",
  CG: "Kongo",
  CR: "Kostarika",
  CU: "Kuba",
  KW: "Kuvajt",
  CY: "Kypr",
  KG: "Kyrgyzst\xE1n",
  LA: "Laos",
  LS: "Lesotho",
  LB: "Libanon",
  LR: "Lib\xE9rie",
  LY: "Libye",
  LI: "Lichten\u0161tejnsko",
  LT: "Litva",
  LV: "Loty\u0161sko",
  LU: "Lucembursko",
  MO: "Macao",
  MG: "Madagaskar",
  HU: "Ma\u010Farsko",
  MY: "Malajsie",
  MW: "Malawi",
  MV: "Maledivy",
  ML: "Mali",
  MT: "Malta",
  IM: "Ostrov Man",
  MA: "Maroko",
  MH: "Marshallovy ostrovy",
  MQ: "Martinik",
  MU: "Mauricius",
  MR: "Maurit\xE1nie",
  YT: "Mayotte",
  UM: "Men\u0161\xED odlehl\xE9 ostrovy USA",
  MX: "Mexiko",
  FM: "Mikron\xE9sie",
  MD: "Moldavsko",
  MC: "Monako",
  MN: "Mongolsko",
  MS: "Montserrat",
  MZ: "Mosambik",
  MM: "Myanmar",
  NA: "Namibie",
  NR: "Nauru",
  DE: "N\u011Bmecko",
  NP: "Nep\xE1l",
  NE: "Niger",
  NG: "Nig\xE9rie",
  NI: "Nikaragua",
  NU: "Niue",
  NL: "Nizozemsko",
  NF: "Norfolk",
  NO: "Norsko",
  NC: "Nov\xE1 Kaledonie",
  NZ: "Nov\xFD Z\xE9land",
  OM: "Om\xE1n",
  PK: "P\xE1kist\xE1n",
  PW: "Palau",
  PS: "Palestinsk\xE1 autonomie",
  PA: "Panama",
  PG: "Papua-Nov\xE1 Guinea",
  PY: "Paraguay",
  PE: "Peru",
  PN: "Pitcairnovy ostrovy",
  CI: "Pob\u0159e\u017E\xED slonoviny",
  PL: "Polsko",
  PR: "Portoriko",
  PT: "Portugalsko",
  AT: "Rakousko",
  RE: "R\xE9union",
  GQ: "Rovn\xEDkov\xE1 Guinea",
  RO: "Rumunsko",
  RU: "Rusko",
  RW: "Rwanda",
  GR: "\u0158ecko",
  PM: "Saint-Pierre a Miquelon",
  SV: "Salvador",
  WS: "Samoa",
  SM: "San Marino",
  SA: "Sa\xFAdsk\xE1 Ar\xE1bie",
  SN: "Senegal",
  KP: "Severn\xED Korea",
  MK: "Severn\xED Makedonie",
  MP: "Severn\xED Mariany",
  SC: "Seychely",
  SL: "Sierra Leone",
  SG: "Singapur",
  SK: "Slovensko",
  SI: "Slovinsko",
  SO: "Som\xE1lsko",
  AE: "Spojen\xE9 arabsk\xE9 emir\xE1ty",
  GB: "Spojen\xE9 kr\xE1lovstv\xED Velk\xE9 Brit\xE1nie a Severn\xEDho Irska",
  US: "Spojen\xE9 st\xE1ty americk\xE9",
  RS: "Srbsko",
  LK: "Sr\xED Lanka",
  CF: "St\u0159edoafrick\xE1 republika",
  SD: "S\xFAd\xE1n",
  SR: "Surinam",
  SH: "Svat\xE1 Helena, Ascension a Tristan da Cunha",
  LC: "Svat\xE1 Lucie",
  BL: "Svat\xFD Bartolom\u011Bj",
  KN: "Svat\xFD Kry\u0161tof a Nevis",
  MF: "Svat\xFD Martin (francouzsk\xE1 \u010D\xE1st)",
  SX: "Svat\xFD Martin (nizozemsk\xE1 \u010D\xE1st)",
  ST: "Svat\xFD Tom\xE1\u0161 a Princ\u016Fv ostrov",
  VC: "Svat\xFD Vincenc a Grenadiny",
  SZ: "Svazijsko",
  SY: "S\xFDrie",
  SB: "\u0160alomounovy ostrovy",
  ES: "\u0160pan\u011Blsko",
  SJ: "\u0160picberky a Jan Mayen",
  SE: "\u0160v\xE9dsko",
  CH: "\u0160v\xFDcarsko",
  TJ: "T\xE1d\u017Eikist\xE1n",
  TZ: "Tanzanie",
  TH: "Thajsko",
  TW: "Tchaj-wan",
  TG: "Togo",
  TK: "Tokelau",
  TO: "Tonga",
  TT: "Trinidad a Tobago",
  TN: "Tunisko",
  TR: "Turecko",
  TM: "Turkmenist\xE1n",
  TC: "Turks a Caicos",
  TV: "Tuvalu",
  UG: "Uganda",
  UA: "Ukrajina",
  UY: "Uruguay",
  UZ: "Uzbekist\xE1n",
  CX: "V\xE1no\u010Dn\xED ostrov",
  VU: "Vanuatu",
  VA: "Vatik\xE1n",
  VE: "Venezuela",
  VN: "Vietnam",
  TL: "V\xFDchodn\xED Timor",
  WF: "Wallis a Futuna",
  ZM: "Zambie",
  EH: "Z\xE1padn\xED Sahara",
  ZW: "Zimbabwe"
};
var countrySchema = objectKeysToZodEnum(countryOptions);

// src/lib/address.ts
var addressSchema = z3.object({
  street: z3.string().min(1),
  descriptiveNumber: z3.number().min(1),
  orientationNumber: z3.string().optional().refine(isOrientationNumber),
  city: z3.string().min(1),
  postalCode: z3.string().refine(isPostalCode)
});
var addressWithCountrySchema = addressSchema.extend({
  countryCode: countrySchema
});

// src/lib/bankAccount.ts
import { z as z4 } from "zod";
var bankAccountSchema = z4.object({
  prefix: z4.string().max(6).optional().refine(isValidBankAccountPrefix),
  number: z4.string().min(2).max(10).refine(isValidBankAccountNumber),
  bankCode: z4.string().length(4).refine(containsOnlyDigits)
  // TODO číselník čísel bank?? BQ a sosat z ČNB
});

// src/lib/circuitBreaker.ts
import { z as z5 } from "zod";
var circuitBreakerOptions = {
  1: "1",
  3: "3"
};
var circuitBreakerPhaseSchema = objectKeysToZodEnum(circuitBreakerOptions);
var circuitBreakerSchema = z5.object({
  phases: circuitBreakerPhaseSchema,
  capacity: z5.number().positive()
});

// src/lib/customer.ts
import { z as z7 } from "zod";

// src/lib/deliveryPoint.ts
import { z as z6 } from "zod";

// src/lib/distributionTariff.ts
var distributionTariffOptions = {
  D01D: "D01d",
  D02D: "D02d",
  D25D: "D25d",
  D26D: "D26d",
  D27D: "D27d",
  D35D: "D35d",
  D45D: "D45d",
  D55D: "D55d",
  D56D: "D56d",
  D57D: "D57d",
  D61D: "D61d"
};
var businessDistributionTariffOptions = {
  C01d: "C01d",
  C02d: "C02d",
  C03d: "C03d",
  C25d: "C25d",
  C26d: "C26d",
  C27d: "C27d",
  C35d: "C35d",
  C45d: "C45d",
  C46d: "C46d",
  C55d: "C55d",
  C56d: "C56d",
  C60d: "C60d",
  C62d: "C62d"
};
var completeDistributionTariffOptions = {
  ...distributionTariffOptions,
  ...businessDistributionTariffOptions
};
var distributionTariffSchema = objectKeysToZodEnum(completeDistributionTariffOptions);
var ONLY_HIGH_TARIFF_OPTIONS = /* @__PURE__ */ new Set(["D01D", "D02D", "C01d", "C02d", "C03d"]);
function hasOnlyHighTariff(distributionTariff) {
  return ONLY_HIGH_TARIFF_OPTIONS.has(distributionTariff);
}

// src/lib/paymentMethod.ts
var paymentMethodOptions = {
  BANK_ORDER: "Bankovn\xED p\u0159evod",
  BANK_ENCASHMENT: "Inkaso"
};
var paymentMethodSchema = objectKeysToZodEnum(paymentMethodOptions);
var invoicingMethodOptions = {
  SUPPLIER_ISSUED_INVOICE: "Faktury mi vystavujte (doporu\u010Den\xE1 mo\u017Enost)",
  CUSTOMER_ISSUED_INVOICE: "Faktury si vystav\xEDm s\xE1m/sama"
};
var invoicingMethodSchema = objectKeysToZodEnum(invoicingMethodOptions);

// src/lib/distributionArea.ts
var distributionAreaOptions = {
  JIHOCESKY: "Jiho\u010Desk\xFD kraj",
  JIHOMORAVSKY: "Jihomoravsk\xFD kraj",
  KARLOVARSKY: "Karlovarsk\xFD kraj",
  KRALOVEHRADECKY: "Kr\xE1lov\xE9hradeck\xFD kraj",
  LIBERECKY: "Libereck\xFD kraj",
  MORAVSKOSLEZSKY: "Moravskoslezsk\xFD kraj",
  OLOMOUCKY: "Olomouck\xFD kraj",
  PARDUBICKY: "Pardubick\xFD kraj",
  PLZENSKY: "Plze\u0148sk\xFD kraj",
  PRAHA: "Hlavn\xED m\u011Bsto Praha",
  STREDOCESKY: "St\u0159edo\u010Desk\xFD kraj",
  USTECKY: "\xDAsteck\xFD kraj",
  VYSOCINA: "Kraj Vyso\u010Dina",
  ZLINSKY: "Zl\xEDnsk\xFD Kraj"
};
var distributionAreaSchema = objectKeysToZodEnum(distributionAreaOptions);

// src/lib/deliveryPoint.ts
var eanSchema = z6.string().length(EAN_LENGTH).refine(isValidEan);
var nonPrefixedEanSchema = z6.string().length(EAN_LENGTH - EAN_PREFIX.length).refine(
  (value) => {
    const fullEan = `${EAN_PREFIX}${value}`;
    return isValidEan(fullEan);
  },
  { message: "EAN nem\xE1 platn\xFD form\xE1t" }
);
var noticePeriodOptions = {
  ONE_MONTH: "1 me\u030Csi\u0301c",
  TWO_MONTHS: "2 me\u030Csi\u0301ce",
  THREE_MONTHS: "3 me\u030Csi\u0301ce",
  OTHER: "Jin\xE1 - vypln\xEDm ve zpr\xE1v\u011B na konci formul\xE1\u0159e"
};
var contractTypeOptions = {
  FIXED_TERM: "Na dobu ur\u010Ditou",
  VARIABLE_TERM: "Na dobu neur\u010Ditou"
};
var contractTypeSchema = objectKeysToZodEnum(contractTypeOptions);
var noticePeriodSchema = objectKeysToZodEnum(noticePeriodOptions);
var fixedTermContractSchema = z6.object({
  contractType: z6.literal(contractTypeSchema.Values.FIXED_TERM),
  shouldTerminateContract: z6.boolean(),
  contractEndDate: z6.date(),
  terminationNoticeInDaysBeforeEnd: z6.union([z6.undefined(), z6.null(), z6.number().min(1).max(99)])
});
var variableTermContractSchema = z6.object({
  contractType: z6.literal(contractTypeSchema.Values.VARIABLE_TERM),
  shouldTerminateContract: z6.boolean(),
  noticePeriod: noticePeriodSchema
});
var consumptionPointSchema = z6.object({
  address: addressWithCountrySchema,
  ean: eanSchema,
  distributionTariff: distributionTariffSchema,
  distributionArea: distributionAreaSchema,
  circuitBreaker: circuitBreakerSchema,
  highTariffConsumptionKWh: z6.number().nonnegative(),
  lowTariffConsumptionKWh: z6.number().nonnegative().optional(),
  paymentMethod: paymentMethodSchema,
  bankAccount: bankAccountSchema,
  currentSupplier: z6.string().min(1).max(100),
  /**
   * @deprecated use `currentContractInfo` instead
   */
  shouldTerminateContract: z6.boolean().optional(),
  /**
   * @deprecated use `currentContractInfo` instead
   */
  contractEndDate: z6.date().optional(),
  /**
   * for now optional, until legacy fields are removed
   */
  currentContractInfo: z6.union([fixedTermContractSchema, variableTermContractSchema]),
  lastBillingPath: z6.string()
});
var productionPointTypes = {
  NEW: "Nov\xE1 v\xFDrobna",
  EXISTING: "St\xE1vaj\xEDc\xED v\xFDrobna"
};
var productionPointTypeSchema = objectKeysToZodEnum(productionPointTypes);
var productionPointSchema = z6.object({
  address: addressWithCountrySchema,
  ean: eanSchema,
  name: z6.string().min(1),
  powerKwp: z6.number().min(0.1).max(50),
  productionDataTariff: z6.boolean(),
  bankAccount: bankAccountSchema,
  type: productionPointTypeSchema,
  invoicingMethod: invoicingMethodSchema,
  shouldTerminateContract: z6.boolean().optional(),
  currentSupplier: z6.union([z6.string().min(1).max(100), z6.undefined()]),
  connectionContractPath: z6.string(),
  currentContractTermination: z6.object({
    effectiveDate: z6.date(),
    terminationContractPath: z6.string()
  }).optional()
});

// src/lib/customer.ts
var productDataSchema = z7.union([
  z7.object({
    product: z7.literal("CONSUMPTION"),
    consumptionPoint: consumptionPointSchema
  }),
  z7.object({
    product: z7.literal("PRODUCTION"),
    productionPoint: productionPointSchema
  }),
  z7.object({
    product: z7.literal("PRODUCTION_AND_CONSUMPTION"),
    consumptionPoint: consumptionPointSchema,
    productionPoint: productionPointSchema
  })
]);
var customerType = z7.enum(["household", "business"]);
var businessInfoSchema = z7.object({
  companyName: z7.string().min(2),
  registrationNumber: z7.string().min(8).refine(containsOnlyDigits),
  taxNumber: z7.string().optional()
});
var customerTypeDataSchema = z7.union([
  z7.object({
    customerType: z7.literal(customerType.Values.household)
  }),
  z7.object({
    customerType: z7.literal(customerType.Values.business),
    ...businessInfoSchema.shape
  })
]);
var partnerSchema = z7.object({
  code: z7.string(),
  name: z7.string()
});
var customerSchema = z7.object({
  firstName: z7.string().min(2),
  lastName: z7.string().min(2),
  birthDate: z7.date(),
  phone: z7.string().refine(isPhoneNumber),
  email: z7.string().email(),
  address: addressWithCountrySchema,
  customerTypeData: customerTypeDataSchema,
  contactAddress: addressWithCountrySchema.optional(),
  productData: productDataSchema,
  partnerCode: partnerSchema.shape.code.optional()
});
export {
  addressSchema,
  addressWithCountrySchema,
  bankAccountSchema,
  businessDistributionTariffOptions,
  businessInfoSchema,
  circuitBreakerOptions,
  circuitBreakerPhaseSchema,
  circuitBreakerSchema,
  completeDistributionTariffOptions,
  consumptionPointSchema,
  contractTypeOptions,
  contractTypeSchema,
  countryOptions,
  countrySchema,
  customerSchema,
  customerType,
  distributionAreaOptions,
  distributionAreaSchema,
  distributionTariffOptions,
  distributionTariffSchema,
  eanSchema,
  fixedTermContractSchema,
  hasOnlyHighTariff,
  invoicingMethodOptions,
  invoicingMethodSchema,
  nonPrefixedEanSchema,
  noticePeriodOptions,
  noticePeriodSchema,
  partnerSchema,
  paymentMethodOptions,
  paymentMethodSchema,
  productionPointSchema,
  productionPointTypeSchema,
  productionPointTypes,
  variableTermContractSchema
};
